<script>
import { ArrowUpIcon, HeartIcon, EyeIcon, ShoppingCartIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import Course from "@/view/front/components/course";
import API from "@/core/api";
import IndexService from "@/MainServices/IndexService.js";
import Skeleton from "@/view/front/components/skeleton";

/**
 * Shop-product-detail component
 */
export default {
  data() {
    return {
      countval: 1,
      API: API.API_HOME,
      discipline_name: this.$route.params.discipline_name,
      courses: [],
      loading:true
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Course,
    ArrowUpIcon,
    Skeleton
  },
  methods: {

  },
  mounted() {
    IndexService.getDisciplineCourse(
      this.discipline_name,
    ).then((response) => {
      this.courses = response.data;
       this.loading = false;
    });
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-custom bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Programs offered under {{ discipline_name.replace(/-/g, ' ').toUpperCase() }}</h4>
              <div class="page-next"></div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <section class="section">
      <div class="container">
         <Skeleton v-if="loading" />
        <div class="row align-items-center">
        <div class="col-lg-2 col-md-3 col-12 mt-4 pt-2" v-for="(singleCourse, index) in courses" :key="index">
            <Course :course="singleCourse" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
